<template>
  <div class="whole">
    <Nav></Nav>
    <div class="all">
      <div class="title">视觉江苏网视觉版权作品</div>
      <div class="xieyi">购买协议</div>
      <div class="one">
        <div class="one-title">一、重要须知</div>
        <div class="one-content">
          <p>
            视觉江苏网（网址：http://www.vijs.net）是由视觉江苏网关联公司拥有域名、并维护和合法经营、使用的国际互联网网站，视觉版权作品使用方根据本协议，成为经视觉江苏网许可而使用上述网站图片的使用人。
          </p>
          <p>
            本协议是视觉版权作品使用方与视觉江苏网之间就版权作品在全球范围内的许可使用而达成的销售条款。本协议包含了视觉江苏网提供特定用途的图片，许可视觉版权作品使用方以非独占使用为特征的完整协议条款。
          </p>
          <p>本协议所称视觉版权作品包括但不限于图片、视频、创意素材。</p>
          <P>本协议所称视觉版权作品使用方包括但不限于个人和单位。</P>
          <P
            >用户完成注册程序并点击"同意"按钮，即表示用户认可本协议所有的服务条款并与视觉江苏网达成协议!</P
          >
        </div>
      </div>
      <div class="two">
        <div class="two-title">二、图片使用权限</div>
        <div class="two-content">
          <div>
            <p>
              1、视觉江苏网授予视觉版权作品使用方的图片使用权，仅是对特定图片的一次性使用权，使用范围为：
            </p>
            <div class="info">
              <span>A.报纸《 </span>
              <el-input class="shuru" v-model="newsPaper"></el-input>
              <span> 》</span>
            </div>
            <div class="info">
              <span>B.网络《</span>
              <el-input class="shuru" v-model="newWork"></el-input>
              <span>》</span>
            </div>
            <div class="info">
              <span>C.微信公众号《</span>
              <el-input class="shuru" v-model="weChat"></el-input>
              <span> 》</span>
            </div>
            <div class="info">
              <span>D.客户端《</span>
              <el-input class="shuru" v-model="client"></el-input>
              <span>》</span>
            </div>
            <div class="info">
              <span>E.杂志《</span>
              <el-input class="shuru" v-model="zazhi"></el-input>
              <span>》</span>
            </div>
            <div class="info">
              <span>F.书籍《</span>
              <el-input class="shuru" v-model="book"></el-input>
              <span>》</span>
            </div>
            <div class="info">
              <span>G.其他《</span>
              <el-input class="shuru" v-model="other"></el-input>
              <span>》</span>
            </div>
            <p>
              视觉版权作品使用方超出本协议授权范围使用图片，应事先向视觉江苏网说明有关特殊用途及要求，由视觉江苏网另行确定相关的使用费。
            </p>
          </div>
          <p>
            2、视觉版权作品使用方对视觉江苏网授予使用的图片，不得再许可任何第三方对该图片的全部或局部进行再许可；不得向任何第三方对该图片的全部或局部进行买卖、转让、租借，不得将该图片的全部或局部或允许任何第三方将该图片的全部或局部作为任何一种服务标志或商标的全部或局部，不得在除本协议约定之外的任何网站或局域网或网络公告牌（BBS）上复制或展示该图片的全部或局部，不得将该图片的全部或局部放置在网上任何可供下载的位置或FTP格式下，不得将该图片的全部或局部用于诋毁或损害他人名誉、欺诈、侵犯、扭曲事实或其它一切非法用途。否则，由此造成的一切损失由视觉版权作品使用方承担。若视觉江苏网因此受到第三方的索赔，则视觉江苏网有权向视觉版权作品使用方追偿其所受到的所有损失和支出，包括但不限于律师费、诉讼费、赔偿金等。
          </p>
          <p>
            3、未经视觉江苏网明确的事先书面许可，视觉版权作品使用方不得将图片用于本协议未列出的用途。视觉江苏网保证图片内容的完整性，视觉江苏网许可视觉版权作品使用方在不违背作者意愿的前提下基于排版或美观的需要对图片和所附的文字说明进行适当的编辑。视觉版权作品使用方如违反本项规定，则必须全额赔偿视觉江苏网因视觉版权作品使用方的未授权使用而不论以任何方式已产生及可能产生的任何索赔、损害、损失或其他任何支出，包括但不限于律师费、诉讼费、违约金等。
          </p>
          <p>
            4、视觉江苏网应保证图片的著作权没有瑕疵，包括肖像权（特别注明除外）。因图片著作权及肖像权产生商业纠纷，责任由视觉江苏网承担。
          </p>
        </div>
      </div>
      <div class="three">
        <div class="three-title">三、许可使用费</div>
        <div class="three-content">
          <p>
            通过本网络下载图片并使用及时支付功能的使用方，价格以图片下方报价为准；通过协商办法购买本图片的，价格以纸质合同为准。
          </p>
          <p>
            视觉版权作品使用方使用图片后，在出版物出版后30天内将刊物一式两份提供给视觉江苏网，新媒体请在图片下方标注链接。
          </p>
        </div>
        <div class="four">
          <div class="four-title">四、使用要求</div>
          <div class="four-content">
            <p>
              视觉版权作品使用方对于许可使用的每张图片均须附上一个可清晰辨认的图片来源署名；如用在封面，应在内文目录标注：“封面摄影
              某某某”；如用在内文，可在图片说明最后标注“某某某摄
              视觉江苏网供图”。如果视觉版权作品使用方未按上述约定附上此说明，则必须按该图片原使用费标准的五倍支付视觉江苏网违约金，同时承担摄影师提起的因侵犯著作权纠纷产生的一切损失。
            </p>
          </div>
        </div>
        <div class="five">
          <div class="five-title">五、违约责任</div>
          <div class="five-content">
            <p>
              1、如视觉版权作品使用方违反本协议许可他人使用图片的，则视觉版权作品使用方应支付给视觉江苏网擅自许可他人使用图片的双倍使用费作为违约金，同时承担由此给视觉江苏网及摄影师造成的损失。
            </p>
            <p>
              2、因视觉版权作品使用方对图片或说明文字的任何不当使用而可能引起的视觉版权作品使用方或任何第三方的损失或损害，视觉江苏网不承担责任；如因视觉江苏网图片或说明文字的不当而可能引起的视觉版权作品使用方或任何第三方的损失或损害，视觉版权作品使用方不承担责任。
            </p>
            <p>
              3、任何一方均不承担因不可抗力（指不能预见、不能克服且不能避免的客观情况，包括但不限于暴乱、罢工等）所致延迟或无法履行本协议的责任，法律另有规定的除外。
            </p>
          </div>
        </div>
        <div class="six">
          <div class="six-title">六、协议争议的解决方式</div>
          <div class="six-content">
            <p>
              本协议在履行过程中发生的争议，由双方当事人协商解决，协商不成的，任何一方有权向本协议签订地有管辖权的人民法院起诉。胜诉一方有权要求另一方赔偿合理的律师费及其它诉讼费用。
            </p>
          </div>
        </div>
        <div class="seven">
          <div class="seven-title">七、保密义务</div>
          <div class="seven-content">
            <p>
              视觉版权作品使用方须保管好自己的用户名和密码，如因视觉版权作品使用方自己的原因造成用户名和密码泄漏，对因此造成的相关损失应由视觉版权作品使用方自己负责。
            </p>
            <p>
              视觉版权作品使用方如果发现自己的用户名和密码泄漏或者发现任何非法使用其用户名和密码的情况，有义务及时通知视觉江苏网(www.vijs.
              Net)，视觉江苏网(www.vijs. Net)在接到通知后应当及时采取补救措施。
            </p>
          </div>
        </div>
        <div class="eight">
          <el-button type="danger" @click="onSubmit">同意该购买协议</el-button>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function() {
    return {
      newsPaper: "", // 报纸
      newWork: "", // 网络
      weChat: "", // 微信公众号
      client: "", // 客户端
      zazhi: "", // 杂志
      book: "", // 书籍
      other: "" // 其他
    };
  },
  methods: {
    // 确认购买协议
    onSubmit() {
      var thiz = this;
      if (thiz.newsPaper == "") {
        thiz.$message("请在《》里填写报纸种类");
        return;
      }

      if (thiz.newWork == "") {
        thiz.$message("请在《》里填写网络种类");
        return;
      }

      if (thiz.weChat == "") {
        thiz.$message("请在《》里填写微信公众号种类");
        return;
      }

      if (thiz.client == "") {
        thiz.$message("请在《》里填写客户端种类");
        return;
      }
      if (thiz.zazhi == "") {
        thiz.$message("请在《》里填写杂志种类");
        return;
      }

      if (thiz.book == "") {
        thiz.$message("请在《》里填写书籍种类");
        return;
      }

      if (thiz.other == "") {
        thiz.$message("请在《》里填写其他种类");
        return;
      }

      var submitFrom = {};
      submitFrom.newsPaper = thiz.newsPaper;
    }
  }
};
</script>

<style lang="less" scoped>
.all {
  width: 1200px;
  margin: 0 auto;
  height: auto;
  .title {
    font-size: 36px;
    line-height: 95px;
  }
  .xieyi {
    font-size: 32px;
  }
  .one,
  .two,
  .three,
  .four,
  .five,
  .six,
  .seven {
    text-align: left;
    .one-title,
    .two-title,
    .three-title,
    .four-title,
    .five-title,
    .six-title,
    .seven-title,
    .eight-title {
      font-size: 20px;
      font-weight: bold;
      text-indent: 30px;
    }
    .one-content,
    .two-content,
    .three-content,
    .four-content,
    .five-content,
    .six-content,
    .seven-content {
      font-size: 18px;
      line-height: 30px;
      p {
        text-indent: 30px;
      }
      .info {
        margin-left: 34px;
        color: red;
        ::v-deep .el-input__inner {
          border: none;
        }
        .shuru {
          width: 200px;
        }
      }
    }
  }
  .eight {
    margin: 5px 0px;
    text-align: center;
  }
}
</style>
